const messages = {
  'p.change.applicant': '发起人',
  'p.change.submittedDate': '提交日期',
  'p.change.productionLine': '产线',
  'p.change.changeType': '变更形式',
  'p.change.changeClarification': '6M变更分类',
  'p.change.description': '变更内容',
  'p.change.changeStatus': '变更单状态',
  'p.change.owner': '责任部门主管',
  'p.change.quality': '质量审批人',
  'p.change.action': '操作',
  'p.change.addchangeissue': '添加变更申请',
  'p.change.date': '日期',
  'p.change.changeType.placeholder': '请选择变更形式',
  'p.change.issueAction': '应对措施',
  'p.change.actionowner': '责任人',
  'p.change.dueDate': '目标期限',
  'p.change.actionStatus': '状态',
  'p.change.actionStatusDone': '措施是否完成',
  'p.change.pleseSelect': '请选择',
  'p.change.pleseinput': '请选择',
  'p.change.serial': '序号',
};
export default messages;
