import axios from 'axios';
import http from './http';
import docCookies from '@/utils/cookie';
import { md5 } from '@/utils/md5';

const cookieKey = '.auth.token';

export function removeRefreshToken() {
  return docCookies.removeItem(cookieKey);
}

export function getRefreshToken() {
  return docCookies.getItem(cookieKey);
}
export function setRefreshToken(token) {
  docCookies.setItem(cookieKey, token);
}

export function authorizeCode(code, redirect_uri) {
  const data = {
    grant_type: 'authorization_code',
    code,
    redirect_uri,
  };
  return getToken(data);
}
export function refreshToken(refresh_token) {
  const data = {
    grant_type: 'refresh_token',
    refresh_token,
  };
  return getToken(data);
}

function getToken(data) {
  data.client_id = 'web';
  data.timestamp = parseInt(new Date().getTime() / 1000);
  data.client_secret = getSecret(data.timestamp);
  return axios
    .post(window.ENV_CFG.token, data)
    .then(res => {
      if (res.data?.access_token) {
        http.setAuthorization(res.data);
        setRefreshToken(res.data.refresh_token);
        return Promise.resolve(res.data);
      } else {
        return Promise.reject(res);
      }
    })
    .catch(e => {
      return Promise.reject(e);
    });
}

function getSecret(timestamp) {
  const hash = md5('0kVOF1PeA9mg6WZf' + timestamp);
  return hash.slice(8, 16 + (timestamp % 16));
}
