const messages = {
  'p.login.error':
    "Something went wrong and we can't sign you in right now. Please try again.",
  'p.login.welcome': 'Welcome to',
  'p.login.employee': 'ABB Employee',
  'p.login.employeetip': 'Sign in with ABB email',
  'p.login.customer': 'ABB Customer',
  'p.login.customertip': 'Query my orders',
  'p.errorpage.403': 'FORBIDDEN',
  'p.errorpage.404': 'PAGE NOT FOUND',
  'p.errorpage.home': 'BACK TO HOME PAGE',
  'p.role.name': 'role',
  'p.role.plural': 'roles',
  'p.user.name': 'user',
  'p.user.plural': 'users',
  'p.user.f.email': 'Email',
  'p.user.f.name': 'Name',
  'p.user.f.roles': 'Roles',
  'p.user.s.kw': 'name, email...',
  'p.user.s.roleph': 'All roles',
  'p.orderpoint.pdpstart': 'Production Start',
  'p.orderpoint.pdpfinish': 'Production Completed',
  'p.orderpoint.pdpbilling': 'PDP Custom Clearance',
  'p.orderpoint.blwarehouse': 'BL in-Warehouse',
  'p.orderpoint.bldeparture': 'BL Delivery(Departure)',
  'p.orderpoint.blarrival': 'BL Delivery(Arrival)',
  'p.contact.pointph': 'All points',
  'p.contact.emailph': 'email...',
  'p.contact.f.ContactPoint': 'Contact Point',
  'p.contact.f.PlantCode': 'Plant',
  'p.contact.f.CompanyCode': 'Company',
  'p.contact.f.PhaseOwnerEmail': 'Phase Owner Email',
  'p.contact.f.PhaseOwnerName': 'Phase Owner Display Name',
  'p.contact.f.Escalation1': '1st Escalation Owner',
  'p.so.s.customerph': 'Customer Name...',
  'p.so.s.projectph': 'Project Number...',
  'p.so.s.robotph': 'Robot Type...',
  'p.so.noorder': 'No Order',
  'p.so.nobtorder': 'No bt orders',
  'p.so.selectorder': 'PLEASE SELECT AN ORDER',
  'p.so.sotitle': 'Contract Details',
  'p.so.dntitle': 'Delivery Information',
  'p.so.createdate': 'Created Date',
  'p.so.releasedate': 'Release Date',
  'p.so.sales': 'Sales',
  'p.so.pm': 'PM',
  'p.so.plandeparture': 'Planned Departure',
  'p.so.actualdeparture': 'Actual Departure',
  'p.so.planarrival': 'Planned Arrival',
  'p.so.actualarrival': 'Actual Arrival',
  'p.so.question': 'Question',
  'p.so.f.RobotType': 'Robot Type',
  'p.so.f.Quantity': 'Quantity',
  'p.so.f.SalesUnit': 'Unit',
  'p.dn.tobedelivered': 'To be delivered',
  'p.dn.ondelivery': 'On delivery',
  'p.dn.delivered': 'Delivered',
  'p.dn.plandeparture': 'Planned Departure',
  'p.dn.actualdeparture': 'Actual Departure',
  'p.dn.planarrival': 'Planned Arrival',
  'p.dn.actualarrival': 'Actual Arrival',
  'p.dn.ActualGIDate': 'SAP Actual GI date',
  'p.dn.tip': '系统仅显示6个月内的物流信息',
  'p.dn.shipmentno': 'Shipement No.',
  'p.bt.notstart': 'Not Started',
  'p.bt.inprod': 'In Production',
  'p.bt.delivered': 'Delivered',
  'p.bt.ontime': 'On Time',
  'p.bt.timegap': 'Time Gap',
  'p.bt.delay': 'Delay',
  'p.bt.plandate': 'Planned Date',
  'p.bt.actualdate': 'Actual Date',
  'p.commun.f.ProjectNumber': 'Project',
  'p.commun.f.QuestionType': 'Order Point',
  'p.commun.f.dnorder': 'Related Order',
  'p.commun.f.RobotType': 'Robot Type',
  'p.commun.f.PlannedDate': 'Planned Date',
  'p.commun.f.ActualDate': 'Actual Date',
  'p.commun.f.PhaseOwner': 'Send to',
  'p.commun.f.QuestionContent': 'Question',
  'p.commun.f.RequestUserName': 'Requested by',
  'p.commun.f.RequestOn': 'Requested on',
  'p.commun.f.ClosedUserName': 'Closed by',
  'p.commun.f.ClosedOn': 'Closed on',
  'p.commun.f.Answered': 'Answered',
  'p.commun.dnhelp':
    'Please enter project number when inquiring delivery questions',
  'p.commun.btlabel': 'BT Order',
  'p.commun.dnlabel': 'Delivery',
  'p.commun.pendinglabel': 'Pending',
  'p.commun.closedlabel': 'Closed',
  'p.commun.questiontitle': 'Question',
  'p.commun.answertitle': 'Answer',
  'p.commun.addaction': 'Add Action',
  'p.commun.closed': 'Closed',
  'p.commun.closecfm': 'Are you sure to close this action?',
  'p.answer.f.DelayReason': 'Delay Reason',
  'p.answer.f.Description': 'Description',
  'p.answer.f.UpdatePlanDate': 'Update Plan Date',
  'p.answer.f.Actions': 'Actions',
  'p.answer.f.ActionContent': 'Action',
  'p.answer.f.ActionOwner': 'Action Owner',
  'p.answer.f.TargetDate': 'Target Date',
  'p.answer.f.ClosedTime': 'Closed On',
  'p.customerorder.pagetitle': 'Orders',
  'p.customerorder.s.customer': 'Customer Code',
  'p.customerorder.s.project': 'Project Number',
  'p.customerorder.searchtip':
    'Please enter the customer code and project number to search ',
  'p.customerorder.noorder': 'NO ORDER FOUND',
  'p.actionworkflowlist.pagetitle': 'Action Workflow List',
  'p.actionworkflowlist.addissue': 'Add Issue',
  'p.actionworkflowlist.mytask': 'My Task',
  'p.actionworkflowlist.myissue': 'My Issue',
  'p.actionworkflowlist.allissue': 'All Issue',
  'p.actionworkflowlist.mytask.pannel.issuetitle': 'My Issue Task',
  'p.actionworkflowlist.mytask.pannel.actiontitle': 'My Action Task',
  'p.actionworkflowlist.issue.columns.issuecreatedby': 'CreatedBy',
  'p.actionworkflowlist.issue.columns.issuecreatedtime': 'CreatedTime',
  'p.actionworkflowlist.issue.columns.issuecontent': 'Issue Content',
  'p.actionworkflowlist.issue.columns.issueowner': 'Issue Owner',
  'p.actionworkflowlist.issue.columns.isrepeat': 'IsRepeat',
  'p.actionworkflowlist.issue.columns.priority': 'Priority',
  'p.actionworkflowlist.issue.columns.issuestatus': 'Issue Status',
  'p.actionworkflowlist.issue.columns.istiermeeting': 'Is Tier Meeting?',
  'p.actionworkflowlist.issue.columns.issuechanneltype': 'Issue Channeltype',
  'p.actionworkflowlist.issue.columns.actioncontent': 'Action Content',
  'p.actionworkflowlist.issue.columns.actoinowner': 'Action Owner',
  'p.actionworkflowlist.issue.columns.actoinstatus': 'Action Status',
  'p.actionworkflowlist.issue.columns.actoinduedate': 'Due Date',
  'p.actionworkflowlist.addissue.attachment.msg':
    'You have not uploaded the attachment. Do you confirm the submission?',
  'p.actionworkflowlist.addissue.addaction.msg':
    'Please add Actions, thank you.',
  'p.actionworkflowlist.issue.reject.msg':
    'Please enter a reason for rejection!',
  'p.actionworkflowlist.issue.transfer.reject.msg':
    'After the rejection, you do not need to re-track the Issue and Action. Do you confirm the rejection?',
  'p.actionworkflowlist.issue.transfer.accept.msg':
    'After acceptance, you will become the new Issue Owner and will not be returnable. Are you sure to accept?',
  'p.actionworkflowlist.issue.transfer.withdraw.msg':
    'After withdrawal, the Issue will retain the original Issue Owner. Are you sure to withdraw?',
  'p.actionworkflowlist.issue.transfer.msg': 'Confirm transfer?',
  'p.actionworkflowlist.issueform.title': 'Action and tracking',
  'p.actionworkflowlist.issueform.issuepannel.title': 'Issue Message',
  'p.actionworkflowlist.issue.columns.beforeissueowner': 'Before Issue Owner',
  'p.actionworkflowlist.issueform.attachment': 'Issue Attachment',
  'p.actionworkflowlist.issueform.addaction.button': 'Add Action',
  'p.actionworkflowlist.issueform.obsolete.button': 'Obsolete',
  'p.actionworkflowlist.issueform.issuetransferpannel.title':
    'Issue Handover Record',
  'p.actionworkflowlist.issueform.issuetransferpannel.comments':
    'Issue Handover Comments',
  'p.actionworkflowlist.issueform.issuetransferpannel.comments.placeholder':
    'Please input issue transfer comments',
  'p.actionworkflowlist.issuetransferform.title': 'Transfer Issue',
  'p.actionworkflowlist.action.columns.createdby': 'CreatedBy',
  'p.actionworkflowlist.action.columns.createdtime': 'CreatedTime',
  'p.actionworkflowlist.action.columns.beforeduedate': 'Before Due Date',
  'p.actionworkflowlist.actionform.actionrecordpannel.title':
    'Response tracking record',
  'p.actionworkflowlist.actionform.obsolete.title': 'Invalid Reason',
  'p.actionworkflowlist.actiondetail.fallback.msg': 'Are you sure to fallback?',
  'p.actionworkflowlist.actiondetail.actionpanel.title': 'Action information',
  'p.actionworkflowlist.actiondetail.actionrecordpanel.title':
    'Response tracking record',
  'p.actionworkflowlist.actiondetail.actionfeedback.title':
    'Response action implementation feedback',
  'p.actionworkflowlist.actiondetail.attachment': 'Feedback the attachment',
  'p.actionworkflowlist.actiondetail.iscompleted': 'Is completed',
  'p.actionworkflowlist.issueform.placeholder.issucontent':
    'Please input issue content',
  'p.actionworkflowlist.actionform.placeholder.actioncontent':
    'Please input action content',
  'p.actionworkflowlist.actionform.placeholder.actionowner':
    'Please input Action Owner',
  'p.actionworkflowlist.actionform.placeholder.duedate':
    'Please select Due Date',
  'p.actionworkflowlist.actionform.placeholder.obsolete':
    'Please enter the reason for invalidation',
  'p.actionworkflowlist.actiondetail.placeholder.content': 'Comments',
  'p.actionworkflowlist.actiondetail.placeholder.content.res':
    'Result Feedback',
  'p.actionworkflowlist.actionform.placeholder.obsoleteContents':
    'Please enter the reason for invalidation!',
  'p.actionworkflowlist.issueform.placeholder.tierMeetting':
    'Please select tierMeetting',
  'p.actionworkflowlist.actionlistworkflow.allisue.button.exptable':
    'Expand all',
  'p.actionworkflowlist.actionlistworkflow.allisue.button.fold': 'All folding',
  'p.actionworkflowlist.actionlistworkflow.actiondetail.button.action':
    'Action',
  'p.actionworkflowlist.issue.cancel.msg':
    'You have not saved the data, whether to confirm to cancel?',
  'p.actionworkflowlist.issue.columns.businessType': 'Problem Sources',
  'p.actionworkflowlist.issue.columns.workflow_issue': 'Action Workflow',
  'p.actionworkflowlist.issue.columns.change_issue': 'Change Management',
  'p.actionworkflowlist.table.pageinfomation': 'Total {value} data ',
  'p.actionworkflowlist.issue.columns.actionOverduty': 'Overdue Owner',
  'p.actionworkflowlist.issue.columns.overduDay': 'Due Day',
  'p.actionworkflowlist.actiondetail.placeholder.content.msg':
    'Please input the comments',
};
export default messages;
