const tpm = {
  'p.tpm.pl.all': 'TPM产线汇总查询',
  'p.tpm.search': '搜索',
  'p.tpm.tpmActivity': 'TPM活动表',
  'p.tpm.tpmActivity.detailTitle': 'TPM活动详情',
  'p.tpm.tpmActivity.add': '新增TPM活动',
  'p.tpm.tpmActivity.applicant': '发起人',
  'p.tpm.tpmActivity.createdDate': '创建日期',
  'p.tpm.tpmActivity.activityDate': '活动日期',
  'p.tpm.tpmActivity.productionLine': '产线',
  'p.tpm.tpmActivity.activityTime': '活动时间',
  'p.tpm.tpmActivity.keyword': '关键字',
  'p.tpm.tpmActivity.activityContent': '活动内容',
  'p.tpm.tpmActivity.action': '操作',
  'p.tpm.tpmActivity.persons': '人员名单',
  'p.tpm.tpmActivity.comment': '备注',
  'p.tpm.tpmActivity.status': '状态',
  'p.tpm.tpmActivity.serial': '序号',
  'p.tpm.tpmActivity.personsdetail': '人员名单列表',
  'p.tpm.tpmActivity.attachment': '附件',

  'p.tpm.status.Submitted': '已提交',
  'p.tpm.status.Draft': '草稿',

  'p.tpm.oplTraining': 'OPL培训记录表',
  'p.tpm.oplTraining.detailTitle': 'OPL培训记录详情',
  'p.tpm.oplTraining.add': '新增OPL培训记录',
  'p.tpm.oplTraining.trainingDate': '培训日期',
  'p.tpm.oplTraining.trainingContent': '培训内容',
  'p.tpm.oplTraining.teacher': '讲师',
  'p.tpm.oplTraining.student': '培训人员',

  'p.tpm.glitchesTracking': '设备故障跟踪记录表',
  'p.tpm.glitchesTracking.detailTitle': '设备故障跟踪记录详情',
  'p.tpm.glitchesTracking.add': '新增设备故障记录',
  'p.tpm.glitchesTracking.description': '故障描述',
  'p.tpm.glitchesTracking.glitchDate': '故障日期',
  'p.tpm.glitchesTracking.equipment': '设备',
  'p.tpm.glitchesTracking.finder': '发现人',
  'p.tpm.glitchesTracking.selfsolve': '自主解决',
  'p.tpm.glitchesTracking.solution': '处理方式',
  'p.tpm.glitchesTracking.confirmResult': '确认结果',

  'p.tpm.defectAndImprovement': '微缺陷/焦点改善记录表',
  'p.tpm.defectAndImprovement.add': '新增微缺陷/焦点改善记录',
  'p.tpm.defectAndImprovement.detailTitle': '微缺陷/焦点改善记录详情',
  'p.tpm.defectAndImprovement.photoExcel': '导出Excel(含图片)',
  'p.tpm.defectAndImprovement.photoExcel.title': '确认生成excel带图片记录',
  'p.tpm.defectAndImprovement.photoExcel.detail':
    '确认提交生成excel带图片记录吗？',
  'p.tpm.defectAndImprovement.photoExcel.success':
    '任务已提交，请到下载任务中查看任务状态。任务完成后可下载文件。',
  'p.tpm.defectAndImprovement.classification': '种类',
  'p.tpm.defectAndImprovement.findDate': '发现日期',
  'p.tpm.defectAndImprovement.description': '问题描述',
  'p.tpm.defectAndImprovement.basicCard': '基础信息',

  'p.tpm.defectAndImprovement.classification.Defect': '微缺陷',
  'p.tpm.defectAndImprovement.classification.Improvement': '焦点改善',
};
export default tpm;
