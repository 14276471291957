const messages = {
  'p.inspection': '检查活动',
  'p.inspection.mypending': '我的待办',
  'p.inspection.plan': '检查计划',
  'p.inspection.task': '检查任务',
  'p.inspection.template': '检查模板',
  'p.inspection.template.view': '查看模板',

  'p.inspection.tempType': '模板类型',
  'p.inspection.tempType.name': '类型名称',
  'p.inspection.tempType.createdName': '创建人',
  'p.inspection.tempType.createdTime': '创建时间',

  'p.inspection.position': '产线站位',
  'p.inspection.position.name': '站位',
  'p.inspection.position.addsub': '添加站位',
  'p.inspection.position.delete.title': '是否要删除该站位',
  'p.inspection.exists': '已存在',

  'p.inspection.otherTier': '非生产检查区域主数据',
  'p.inspection.otherTier.add': '添加非生产检查区域',
  'p.inspection.otherTier.addsub': '添加子区域',
  'p.inspection.otherTier.name': '名称',
  'p.inspection.otherTier.delete.title': '是否要删除该区域',
  'p.inspection.otherTier.exists': '已存在',

  'p.inspection.template.createdBy': '制定者',
  'p.inspection.template.name': '模板名称',
  'p.inspection.template.frequency': '检查频次',
  'p.inspection.template.frequency.day': '每天',
  'p.inspection.template.frequency.week': '每周',
  'p.inspection.template.frequency.month': '每月',
  'p.inspection.template.frequency.towMonth': '每两月',
  'p.inspection.template.frequency.quarter': '季度',
  'p.inspection.template.frequency.single': '单次生效',
  'p.inspection.template.createdTime': '制定时间',
  'p.inspection.template.status': '状态',
  'p.inspection.template.status.Invalid': '已作废',
  'p.inspection.template.status.Draft': '草稿',
  'p.inspection.template.status.Submitted': '已提交',

  'p.inspection.template.add': '添加模板',
  'p.inspection.template.isTierArea': '是否Tier级区域',
  'p.inspection.template.areaParentLevel': '检查区域层级',
  'p.inspection.template.areaParent': '检查区域父级',
  'p.inspection.template.isAllChecked': '检查项是否必检',
  'p.inspection.template.isScore': '检查项是否打分',
  'p.inspection.template.ruleDescription': '使用规则',

  'p.inspection.template.button.cancel.firm': '数据尚未保存，确定取消吗？',
  'p.inspection.template.button.invalid.firm': '确认作废当前模板吗？',
  'p.inspection.template.button.delete.firm': '确认删除当前草稿吗？',

  'p.inspection.template.table': '检查项清单',
  'p.inspection.template.table.serial': '序号',
  'p.inspection.template.table.type': '条目类别',
  'p.inspection.template.table.name': '条目名称',
  'p.inspection.template.table.isException': '是否例外项',
  'p.inspection.template.table.action': '操作',
  'p.inspection.template.table.add': '添加条目',
  'p.inspection.template.table.download': '下载导入模板',
  'p.inspection.template.table.upload': '清单上传',
  'p.inspection.template.table.info.undefined': '数据验证失败',

  'p.inspection.template.check': '检查项目',
  'p.inspection.template.isCheckAll': '是否全检',

  'p.inspection.template.check.type': '检查项目类型',
  'p.inspection.template.check.name': '检查项目名称',
  'p.inspection.template.check.detail': '细则说明',

  'p.inspection.plan.createdBy': '发起人',
  'p.inspection.plan.createdTime': '发起时间',
  'p.inspection.plan.serialNo': '计划序列号',
  'p.inspection.plan.name': '计划名称',
  'p.inspection.plan.templateName': '模板名称',
  'p.inspection.plan.inspectionPerson': '检查人员',
  'p.inspection.plan.startDate': '计划开始时间',
  'p.inspection.plan.endDate': '计划结束时间',
  'p.inspection.plan.task': '计划完成度',
  'p.inspection.plan.status': '状态',
  'p.inspection.plan.action': '操作',
  'p.inspection.plan.inspectionArea': '计划检查区域',
  'p.inspection.plan.comment': '备注',

  'p.inspection.plan.add': '添加检查计划',

  'p.inspection.plan.status.Draft': '草稿',
  'p.inspection.plan.status.Wait': '待接受',
  'p.inspection.plan.status.Accepted': '已接受',
  'p.inspection.plan.status.Close': '已关闭',
  'p.inspection.plan.status.Rejected': '拒绝',
  'p.inspection.plan.status.Interrupt': '中断',
  'p.inspection.plan.status.undefined': '',
  'p.inspection.plan.action.task': '检查任务',
  'p.inspection.plan.button.interrupt.firm': '是否中断该计划？',

  'p.inspection.task.createdBy': '发起人',
  'p.inspection.task.startDate': '检查开始时间',
  'p.inspection.task.endDate': '检查结束时间',
  'p.inspection.task.executionTime': '检查执行时间',
  'p.inspection.task.breakOffOperator': '中断人',
  'p.inspection.task.executionDate': '检查日期',

  'p.inspection.task.createdTime': '发起时间',
  'p.inspection.task.status': '状态',
  'p.inspection.task.button.finally': '执行完成',
  'p.inspection.task.button.finally.notification.title': '执行失败',
  'p.inspection.task.button.finally.notification.havingNotCheck':
    '请完成所有检查条目检查！',
  'p.inspection.task.button.finally.notification.havingNotIssue':
    '请添加有待改进检查条目发现项',
  'p.inspection.task.button.finally.notification.havingNotScore':
    '请给检查条目打分！',
  'p.inspection.task.heard': '执行检查任务',
  'p.inspection.task.accompanyingPerson': '陪同检查者',
  'p.inspection.task.heard.title': '基本信息',
  'p.inspection.task.actualInspectionArea': '实际检查区域',
  'p.inspection.task.taskCheckItem': '检查条目',
  'p.inspection.task.table.result': '结果确认',
  'p.inspection.task.table.score': '结果分数',
  'p.inspection.task.table.explain': '说明',
  'p.inspection.task.table.score.warning': '结果总分数不能大于100',

  'p.inspection.task.table.footer.count': '发现项数量',
  'p.inspection.task.table.footer.score': '检查结果总分',

  'p.inspection.task.table.result.Unchecked': '未检查',
  'p.inspection.task.table.result.Through': '满足要求',
  'p.inspection.task.table.result.NoThrough': '有待改进',

  'p.inspection.task.status.Wait': '待执行',
  'p.inspection.task.status.ExWait': '超期待执行',
  'p.inspection.task.status.Interrupt': '中断',
  'p.inspection.task.status.Executed': '已执行',
  'p.inspection.task.status.Close': '已关闭',

  'p.inspection.task.find.titel': '发现项',
  'p.inspection.task.find.Add': '添加发现项',
  'p.inspection.task.find.table.description': '发现内容',
  'p.inspection.task.find.table.files': '发现项图片',
};
export default messages;
