import { lazy } from 'react';
//import SideMenu from '../components/SideMenu';
import LayoutPlain from '../components/LayoutPlain';
import HomeDefault from '../components/HomeDefault';
import TPMDefault from '../components/TPMDefault';

// auth: 验证权限
// menu: 菜单，如果为空与auth一致
// anonymous: 默认false
// layout: 默认LayoutDefault
// exact: 是否精确匹配
// routes: 子路由
const routes = [
  {
    auth: 'home',
    path: '/home',
    component: lazy(() => import('../features/home/home')),
    layout: HomeDefault,
  },
  {
    auth: 'tier1home',
    path: '/myproductionlines',
    component: lazy(() => import('../features/tier1/home/UserPLList')),
    layout: LayoutPlain,
  },
  {
    auth: 'tier1home',
    path: '/myproductionlines/:plid',
    component: lazy(() => import('../features/tier1/home/UserPLHome')),
    layout: LayoutPlain,
    routes: [
      {
        auth: 'safety',
        path: 'safety',
        component: lazy(() => import('../features/tier1/safety/SafetyMain')),
      },
      {
        auth: 'quality',
        path: 'quality',
        component: lazy(() => import('../features/tier1/quality/QualityMain')),
      },
    ],
  },
  {
    auth: 'changeManagement',
    path: '/changeManagement',
    component: lazy(() => import('../features/changeManagement/mainview/Main')),
    routes: [
      {
        auth: 'changeManagementHome',
        path: 'changeManagementHome',
        component: lazy(() => import('../features/changeManagement/home/Home')),
      },
    ],
  },
  {
    auth: 'productionline',
    path: '/masterdata/productionline',
    component: lazy(() =>
      import('../features/masterdata/organization/OrgHome')
    ),
  },
  {
    auth: 'TPMDashboard',
    path: '/TPMmyproductionlines',
    component: lazy(() => import('../features/TPM/home/UserPLList')),
  },
  {
    auth: 'TPMDashboard',
    path: '/TPMmyproductionlines/:plid',
    component: lazy(() => import('../features/TPM/home/UserPLHome')),
    layout: TPMDefault,
    routes: [
      {
        auth: 'TPMActivity',
        path: 'TPMActivity',
        component: lazy(() =>
          import('../features/TPM/TPMActivity/TPMActivityHome')
        ),
      },
      {
        auth: 'OPLTraining',
        path: 'OPLTraining',
        component: lazy(() =>
          import('../features/TPM/OPLTraining/OPLTrainingHome')
        ),
      },
      {
        auth: 'GlitchesTracking',
        path: 'GlitchesTracking',
        component: lazy(() =>
          import('../features/TPM/GlitchesTracking/GlitchesTrackingHome')
        ),
      },
      {
        auth: 'DefectAndImprovement',
        path: 'DefectAndImprovement',
        component: lazy(() =>
          import(
            '../features/TPM/DefectAndImprovement/DefectAndImprovementHome'
          )
        ),
      },
    ],
  },
  {
    auth: 'qualityissuetype',
    path: '/masterdata/qualityissuetype',
    component: lazy(() =>
      import('../features/masterdata/qualityissue/QualityIssueList')
    ),
  },
  {
    auth: 'tiermeeting',
    path: '/masterdata/tierMeeting',
    component: lazy(() =>
      import('../features/masterdata/tierMeeting/TierMeeting')
    ),
  },
  {
    auth: 'temptype',
    path: '/masterdata/temptype',
    component: lazy(() =>
      import('../features/masterdata/tempType/TempTypeHome')
    ),
  },
  {
    auth: 'othertier',
    path: '/masterdata/othertier',
    component: lazy(() =>
      import('../features/masterdata/OtherTier/OtherTierHome')
    ),
  },
  {
    auth: 'position',
    path: '/masterdata/position',
    component: lazy(() =>
      import('../features/masterdata/position/PositionHome')
    ),
  },
  {
    auth: 'actionlistworkflow',
    path: '/actionlistworkflow',
    component: lazy(() =>
      import('../features/workflowMaintenance/ActionListWorkflow')
    ),
  },
  {
    auth: 'actionlistworkflow',
    path: '/actionlistworkflow/:type/:id',
    component: lazy(() =>
      import('../features/workflowMaintenance/ActionListWorkflow')
    ),
  },
  {
    auth: 'inspectionModule',
    path: '/inspection',
    component: lazy(() => import('../features/inspection/home/Home')),
  },
  {
    auth: 'user',
    path: '/user',
    component: lazy(() => import('../features/setting/user/UserList')),
  },
  {
    auth: 'user',
    path: '/user/:roleid',
    component: lazy(() => import('../features/setting/user/UserList')),
  },
  {
    auth: 'menu',
    path: '/setting/menu',
    component: lazy(() => import('../features/setting/menu/MenuList')),
  },
  {
    auth: 'role',
    path: '/setting/role',
    component: lazy(() => import('../features/setting/role/RoleList')),
  },
  {
    auth: 'appconfig',
    path: '/setting/configuration',
    component: lazy(() => import('../features/setting/config/ConfigList')),
  },
  {
    auth: 'message',
    path: '/setting/message',
    component: lazy(() => import('../features/setting/message/MessageList')),
  },
  {
    path: '/signin',
    component: lazy(() => import('../features/auth/SignIn')),
    anonymous: true,
    layout: false,
  },
  {
    path: '/signin-internal-use-only',
    component: lazy(() => import('../features/auth/SignInTest')),
    anonymous: true,
    layout: false,
  },
];

function standardize(items) {
  for (let item of items) {
    // menu
    if (item.auth && !item.menu) item.menu = item.auth;
    // anonymous
    item.anonymous = item.anonymous === true ? true : false;
    // exact
    if (item.exact === undefined)
      item.exact = !(item.routes && item.routes.length > 0);
    if (item.routes) standardize(item.routes);
  }
}

standardize(routes);

export default routes;
