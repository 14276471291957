const messages = {
  'menu.setting': 'Settings',
  'menu.menu': 'Menus',
  'menu.role': 'Roles',
  'menu.message': 'Mail Messages',
  'menu.appconfig': 'Configurations',
  'menu.user': 'Users',
  'menu.home': 'Home',
};
export default messages;
