import error from './zh-CN/error';
import menu from './zh-CN/menu';
import pages from './zh-CN/pages';
import change from './zh-CN/change';
import tpm from './zh-CN/tpm';
import inspection from './zh-CN/inspection';

const zhCN = {
  'common.loading': '加载中...',
  'common.search': '查询',
  'common.clear': '清除',
  'common.add': '添加',
  'common.edit': '编辑',
  'common.delete': '删除',
  'common.save': '保存',
  'common.cancel': '取消',
  'common.submit': '提交',
  'common.reset': '重置',
  'common.close': '关闭',
  'common.delcfm': '确认要删除{value}吗？',
  'common.thisitem': '这条记录',
  'common.deleting': '正在删除...',
  'common.yes': '是',
  'common.no': '否',
  'common.changeProductionLine': '切换产线',
  'common.unknown': '未知',
  'common.nocontent': '无内容',
  'common.loadmore': '加载更多',
  'common.success': '成功',
  'common.finish': '完成',
  'common.confirm': '确定',
  'common.copy': '复制',
  'common.invalid': '作废',
  'common.fallback': '回退',
  'common.reject': '拒绝',
  'common.access': '接受',
  'common.transfer': '移交',
  'common.withdrawal': '撤回',
  'common.handle': '处理',
  'common.detail': '查看',
  'common.action': '操作',
  'common.keyword': '关键字',
  'common.attachment': '请选择附件',
  'common.exportexcel': '导出 Excel',
  'common.obsolete': '确认要作废{value}吗？',
  'common.example': '例如：{value}',
  'layout.appname': 'CN PDP Digital Management Platform',
  'layout.lang': '多语言',
  'layout.signout': '退出',
  'common.applicant': '发起人',
  'common.pleseSelect': '请选择',
  'common.pleseInput': '请输入',
  ...error,
  ...menu,
  ...pages,
  ...change,
  ...tpm,
  ...inspection,
};
export default zhCN;
