import React, { useState } from 'react';
import { Menu, Divider } from 'antd';
import {
  UserOutlined,
  ExportOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { selectMenus, selectName, signOut } from '../features/auth/authSlice';
import { Link } from 'react-router-dom';
import './LayoutDefault.less';
import { FormattedMessage } from 'react-intl';
import DownloadDrawer from '@/features/TPM/Tools/DownloadDrawer';
//import LanguageSetting from './LanguageSetting';

function LayoutDefault({ children, menu }) {
  let year = new Date().getFullYear();
  const [dDV, setDDV] = useState(false);
  return (
    <div className="layout-default">
      <div className="app-header-container">
        <div className="app-header-wrapper">
          <div className="abb-bar">
            <FormattedMessage id="layout.appname" />
          </div>
          <div className="app-header-bar">
            <Navbar currentMenu={menu} />
            <Toolbar dDVBtn={() => setDDV(true)} />
          </div>
        </div>
      </div>
      <div className="app-body-container">
        <div className="app-body-wrapper">
          {children}
          <DownloadDrawer visible={dDV} onClose={() => setDDV(false)} />
        </div>
      </div>
      <div className="app-footer-container">
        <div className="app-footer-wrapper">&copy; {year} ABB</div>
      </div>
    </div>
  );
}

export default LayoutDefault;

// child component
function Navbar({ currentMenu }) {
  // component
  const { SubMenu } = Menu;
  // selector
  const menus = useSelector(selectMenus);

  function getNavItems(items) {
    let navItems = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      let navitem = {
        key: item.menuCode,
        label: item.menuName,
      };
      if (item.children && item.children.length > 0) {
        navitem.children = [];
        for (let j = 0; j < item.children.length; j++) {
          const x = item.children[j];
          let navcitem = {
            key: x.menuCode,
            label: (
              <Link to={x.url == undefined ? '' : x.url}>{x.menuName}</Link>
            ),
          };
          navitem.children.push(navcitem);
        }
      } else {
        navitem.label = <Link to={item.url}>{item.menuName}</Link>;
      }
      navItems.push(navitem);
    }
    return navItems;
    // return items.map(x =>
    //   x.children && x.children.length > 0 ? (
    //     <SubMenu key={x.menuCode} title={x.menuName}>
    //       {getNavItems(x.children)}
    //     </SubMenu>
    //   ) : (
    //     <Menu.Item key={x.menuCode}>
    //       <Link to={x.url == undefined ? '' : x.url}>{x.menuName}</Link>
    //     </Menu.Item>
    //   )
    // );
  }

  return (
    <Menu
      selectedKeys={[currentMenu]}
      mode="horizontal"
      items={getNavItems(menus)}
    />
  );
}

function Toolbar(props) {
  const dispatch = useDispatch();
  const name = useSelector(selectName);
  const handleLogout = () => {
    dispatch(signOut());
    window.location.href = window.ENV_CFG.signout;
  };

  return (
    <div className="app-header-bar-controls">
      {/* <div className="control-item">
        <LanguageSetting className="lang" />
      </div> */}
      <div
        className="control-item"
        onClick={() => {
          props.dDVBtn();
        }}
      >
        <DownloadOutlined />
        <FormattedMessage id="layout.download" />
      </div>
      {name && name.length > 0 && (
        <React.Fragment>
          <Divider type="vertical" />
          <div className="control-item">
            <UserOutlined /> {name}
          </div>
          <Divider type="vertical" />
          <div className="control-item" onClick={handleLogout}>
            <ExportOutlined />
            <FormattedMessage id="layout.signout" />
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
