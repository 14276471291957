import React, { useEffect, useState } from 'react';
import { Drawer, Tooltip, Modal, Space, Table, Row, Col, Button } from 'antd';
import { useIntl } from 'react-intl';
import {
  GetMyTables,
  ClearJobDwonloads,
  RetryJobDwonloads,
  UpdateDownLoad,
} from '@/api/job';
import moment from 'moment';

const DownloadDrawer = props => {
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setselectedRowKeys] = useState([]);

  //多语言
  const intl = useIntl();
  const intlText = id => {
    return intl.formatMessage({ id: id });
  };

  useEffect(() => {
    GetMyTables().then(ref => setListData(ref));
    setLoading(false);
  }, [props, loading]);

  const handClear = () => {
    Modal.confirm({
      title: intlText('p.downloadDrawer.title'),
      content: intlText('p.downloadDrawer.table.clear'),
      onOk() {
        selectedRowKeys.map(l => {
          ClearJobDwonloads(l.toString()).finally(() =>
            GetMyTables().then(ref => setListData(ref))
          );
        });
      },
    });
  };

  const handRetry = () => {
    selectedRowKeys.map(l => {
      RetryJobDwonloads(l.toString()).finally(() =>
        GetMyTables().then(ref => setListData(ref))
      );
    });
  };

  const handDownload = item => {
    UpdateDownLoad(item.key).finally(() => setLoading(true));

    console.log(listData.find(l => l.key == item.key));
    listData.find(l => l.key == item.key).isDownLoad = true;
    setListData(listData);
  };
  const onSelectChange = selectedRowKey => {
    setselectedRowKeys(selectedRowKey);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: intlText('p.downloadDrawer.table.fileName'),
      dataIndex: 'fileName',
      ellipsis: {
        showTitle: false,
      },
      render: fileName => (
        <Tooltip placement="topLeft" title={fileName}>
          {fileName}
        </Tooltip>
      ),
    },
    {
      title: intlText('p.tpm.tpmActivity.createdDate'),
      dataIndex: 'createdDate',
      render: (text, item) => {
        return moment(text).format('YYYY-MM-DD HH:mm:ss');
      },
      with: '200',
    },
    {
      title: intlText('p.downloadDrawer.table.status'),
      dataIndex: 'status',
      render: (text, item) => {
        return intlText('p.downloadDrawer.table.status.' + text.toLowerCase());
      },
      with: '200',
    },
    {
      title: intlText('p.downloadDrawer.table.isDownload'),
      dataIndex: 'isDownLoad',
      render: (text, item) => {
        if (item.isDownLoad) {
          return intlText('common.yes');
        }
        if (!item.isDownLoad) {
          return intlText('common.no');
        }
      },
      with: '200',
    },
    {
      title: '',
      dataIndex: 'downloadLink',
      render: (text, item) => {
        if (item.status.toLowerCase() == 'failure')
          return intlText('p.downloadDrawer.table.dataTooBig');
        if (item.status.toLowerCase() == 'success')
          return (
            <Button
              type="link"
              href={text}
              target={'_blank'}
              onClick={() => handDownload(item)}
            >
              {intlText('p.downloadDrawer.table.download')}
            </Button>
          );
      },
      minwith: '200',
    },
  ];
  return (
    <Drawer
      title={intlText('p.downloadDrawer.title')}
      placement="right"
      width={'40%'}
      {...props}
    >
      <Row>
        <Col span={18} />
        <Col span={4}>
          <Space>
            <Button
              size={'small'}
              disabled={!hasSelected}
              type="primary"
              onClick={handClear}
            >
              {intlText('p.downloadDrawer.clear')}
            </Button>
            <Button size={'small'} disabled={!hasSelected} onClick={handRetry}>
              {intlText('p.downloadDrawer.retry')}
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        itemLayout={'vertical'}
        loading={loading}
        dataSource={listData}
        rowSelection={rowSelection}
        columns={columns}
      />
    </Drawer>
  );
};

export default DownloadDrawer;
