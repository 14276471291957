const messages = {
  'menu.setting': '设置',
  'menu.menu': '菜单',
  'menu.role': '角色',
  'menu.message': '邮件',
  'menu.appconfig': '配置',
  'menu.user': '用户',
  'menu.home': '主页',
};
export default messages;
