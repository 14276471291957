import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getProductionLines } from '@/api/organization';
import { REQUEST_STATUS } from '@/utils/constants';

const initialState = {
  status: REQUEST_STATUS.IDLE,
  list: [],
};

// async thunks
export const fetchList = createAsyncThunk(
  'userPL/fetchList',
  async (userPLArgs, { rejectWithValue }) => {
    try {
      const response = await getProductionLines();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// slice
export const userPLSlice = createSlice({
  name: 'userPL',
  initialState,
  reducers: {
    selectPL(state, action) {
      state.selected = action.payload;
      return state;
    },
  },
  extraReducers: {
    [fetchList.pending]: (state, action) => {
      state.status = REQUEST_STATUS.PENDING;
    },
    [fetchList.fulfilled]: (state, action) => {
      state.status = REQUEST_STATUS.SUCCEEDED;
      state.list = action.payload;
    },
    [fetchList.rejected]: (state, action) => {
      state.status = REQUEST_STATUS.FAILED;
    },
  },
});

// reducer
export default userPLSlice.reducer;

// actions
export const { selectPL } = userPLSlice.actions;

// selector
export const selectUserPLStatus = state => state.userPL.status;
export const selectUserPLList = state => state.userPL.list;
