import http from './http';

export function GetMyTables() {
  return http.post('/Job/GetTables');
}
export function ClearJobDwonloads(id) {
  return http.delete('/Job/ClearJobDwonloads?id=' + id);
}
export function RetryJobDwonloads(id) {
  return http.post('/Job/RetryJobDwonloads?id=' + id);
}

export function UpdateDownLoad(id) {
  return http.post('/Job/UpdateDownLoad?id=' + id);
}
